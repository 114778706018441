<template>
  <div class="col-md-12" v-if="nextPayments != null && activeAlert">
    <div class="alert alert-warning" role="alert">
      Revisa tu próximo pago, tu mensualidad está por expirar.
    </div>
  </div>
  <div class="col-md-12" v-if="nextPayments != null && inDebt">
    <div class="alert alert-danger" role="alert">
      Actualmente tiene un adeudo, revise sus pagos pendientes.
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
const { DateTime } = require("luxon");
export default {
  props: {
    nextPayments: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const payments = ref(props.nextPayments);
    const activeAlert = ref(false);
    const inDebt = ref(false);

    const checkNextPaymentStatus = () => {
      if (payments.value != null || payments.value != undefined) {
        const payDayMinus = DateTime.fromISO(payments.value.date).minus({
          days: 5,
        });
        const payDay = DateTime.fromISO(payments.value.date);
        const today = DateTime.now();
        if (today.ts > payDayMinus.ts && today.ts < payDay.ts) {
          activeAlert.value = true;
        }
        if(today.ts > payDay.ts && payments.value.status == 'pendiente'){
          inDebt.value = true;
        }
      }
    };
    checkNextPaymentStatus();
    return {
      activeAlert,
      inDebt,
    };
  },
};
</script>

<style></style>
